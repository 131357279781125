import React from 'react';
import { Link } from 'react-router-dom';
import logotipo from '../../../assets/logotipo.png';
import './Logo.css';

interface LogoProps {
  className?: string;
  linkTo?: string;
  width?: number;
  height?: number;
  noLink?: boolean;
}

const Logo: React.FC<LogoProps> = ({ 
  className = '', 
  linkTo = '/', 
  width = 80, 
  height = 80,
  noLink = false
}) => {
  const logoContent = (
    <div className={`logo-container ${className}`.trim()}>
      <img 
        src={logotipo} 
        alt="Receitas Verdes - Árvore colorida representando vida e natureza" 
        className="logo-image"
        width={width}
        height={height}
        loading="lazy"
        itemProp="logo"
      />
      <span className="logo-text">Receitas Verdes</span>
    </div>
  );

  if (!noLink && linkTo) {
    return (
      <Link 
        to={linkTo} 
        aria-label="Ir para a página inicial"
        className={className}
      >
        {logoContent}
      </Link>
    );
  }

  return logoContent;
};

export default Logo; 
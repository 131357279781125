import React, { createContext, useContext, ReactNode } from 'react';

export type AmazonCategory = 
  | 'utensilios' 
  | 'eletrodomesticos' 
  | 'ingredientes' 
  | 'livros' 
  | 'organização' 
  | 'panelas';

export interface AmazonProduct {
  id: string;
  title: string;
  price: string;
  image: string;
  category: AmazonCategory;
}

interface AmazonContextType {
  products: {
    [key in AmazonCategory]: AmazonProduct[];
  };
  associateId: string;
}

const defaultContext: AmazonContextType = {
  products: {
    utensilios: [
      {
        id: 'B07T4M1MQW',
        title: 'Panela Wok Aluminio, Loreto, Tramontina, Vermelho, 28 cm',
        price: 'R$ 105,90',
        image: 'https://m.media-amazon.com/images/I/51HfklOJ+sL._AC_SX679_.jpg',
        category: 'utensilios'
      },
      {
        id: 'B07WR4TP9W',
        title: 'Jogo de Facas Tramontina Plenus com Lâminas em Aço Inox e Cabos de Polipropileno Preto 9 Peças',
        price: 'R$ 66,32',
        image: 'https://m.media-amazon.com/images/I/51oCxGHT-aL._AC_SX679_.jpg',
        category: 'utensilios'
      },
      {
        id: 'B08CPLKQ32',
        title: 'KIT 6 Potes de Vidro Retangular com Tampa Hermética 640ml Cor:Azul (QH)',
        price: 'R$ 78,97',
        image: 'https://m.media-amazon.com/images/I/61EpuPD+QaL._AC_SX679_.jpg',
        category: 'utensilios'
      },
      {
        id: 'B0D98S2PTZ',
        title: 'Aparelho de Jantar Jogo de Pratos Rasos Barô by Tramontina 25 cm Rústico Marrom Porcelana Decorada 6 Peças',
        price: 'R$ 174,90',
        image: 'https://m.media-amazon.com/images/I/61Bo5lJ3UoL._AC_SX679_.jpg',
        category: 'utensilios'
      },
      {
        id: 'B08CLVGN79',
        title: 'Jogo de Utensílios Tramontina Ability em Nylon Preto 05 Peças',
        price: 'R$ 48,48',
        image: 'https://m.media-amazon.com/images/I/51e5hf2mBJL._AC_SX679_.jpg',
        category: 'utensilios'
      },
      {
        id: 'B0B483R9ZT',
        title: 'Forma De Bolo Antiaderente 24cm Rochedo Bolo Perfeito',
        price: 'R$ 48,40',
        image: 'https://m.media-amazon.com/images/I/41VFFhWZCOL._AC_SX679_.jpg',
        category: 'utensilios'
      }
    ],
    eletrodomesticos: [
      {
        id: 'B08C6FYSFF',
        title: 'Batedeira Prática, Mondial, Preto, 400W, 220V - B-44-B',
        price: 'R$ 89,00',
        image: 'https://m.media-amazon.com/images/I/71a-aiH-n+L._AC_SX679_.jpg',
        category: 'eletrodomesticos'
      },
      {
        id: 'B076HYTDP3',
        title: 'Centrifuga de Alimentos,1000, 800w, Preto, 220v, Britânia',
        price: 'R$ 483,91',
        image: 'https://m.media-amazon.com/images/I/51B1Tek4R1L._AC_SX679_.jpg',
        category: 'eletrodomesticos'
      },
      {
        id: 'B0B5B7ZCZZ',
        title: 'Sanduicheira e Grill Britânia BGR27I Press 2 em 1 850W 220V',
        price: 'R$ 125,79',
        image: 'https://m.media-amazon.com/images/I/5136pVcmWhL._AC_SX679_.jpg',
        category: 'eletrodomesticos'
      },
      {
        id: 'B076CHG8X5',
        title: 'Laminador De Massas 28 Cm Com Cortador-Branco-110v AND',
        price: 'R$ 580,17',
        image: 'https://m.media-amazon.com/images/I/51+to58d3aL._AC_SX679_.jpg',
        category: 'eletrodomesticos'
      }
    ],
    ingredientes: [],
    livros: [],
    organização: [],
    panelas: [
      {
        id: 'B09W451R6P',
        title: 'Tramontina JOGO DE FRIGIDEIRAS 3PCS CARIBE VERMELHO - SORTIDOS DE ALUMINIO COM REV ANTIADERENTE',
        price: 'R$ 75,15',
        image: 'https://m.media-amazon.com/images/I/81FwSobL5rL._AC_SX679_.jpg',
        category: 'panelas'
      },
      {
        id: 'B0DCGJKLP5',
        title: 'Jogo de Panelas Tramontina Turim em Alumínio com Revestimento Interno e Externo em Antiaderente Starflon Max Preto 10 Peças',
        price: 'R$ 329,99',
        image: 'https://m.media-amazon.com/images/I/51wM7rf+U2L._AC_SX679_.jpg',
        category: 'panelas'
      }
    ]
  },
  associateId: process.env.REACT_APP_AMAZON_ASSOCIATE_ID || ''
};

const AmazonContext = createContext<AmazonContextType | undefined>(undefined);

export const useAmazon = () => {
  const context = useContext(AmazonContext);
  if (context === undefined) {
    throw new Error('useAmazon must be used within an AmazonProvider');
  }
  return context;
};

interface AmazonProviderProps {
  children: ReactNode;
  value?: AmazonContextType;
}

export const AmazonProvider: React.FC<AmazonProviderProps> = ({ children, value = defaultContext }) => {
  return (
    <AmazonContext.Provider value={value}>
      {children}
    </AmazonContext.Provider>
  );
};

export default AmazonProvider; 
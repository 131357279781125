import React from 'react';

export const lazyComponents = {
  Home: React.lazy(() => import('../pages/Home/Home')),
  DetalhesReceita: React.lazy(() => import('../pages/DetalhesReceitas/DetalhesReceitas')),
  ReceitasSimples: React.lazy(() => import('../pages/ReceitasSimples/ReceitasSimples')),
  ReceitasVeganas: React.lazy(() => import('../pages/ReceitasVeganas/ReceitasVeganas')),
  ReceitasVegetarianas: React.lazy(() => import('../pages/ReceitasVegetarianas/ReceitasVegetarianas')),
  Bolos: React.lazy(() => import('../pages/Bolos/Bolos')),
  Tortas: React.lazy(() => import('../pages/Tortas/Tortas')),
  Sobre: React.lazy(() => import('../pages/Sobre/Sobre')),
  PoliticasPrivacidade: React.lazy(() => import('../pages/PoliticasPrivacidade/PoliticasPrivacidade')),
  TermosUso: React.lazy(() => import('../pages/TermosUso/TermosUso')),
  PoliticaCookies: React.lazy(() => import('../pages/PoliticaCookies/PoliticaCookies')),
  PoliticaComentarios: React.lazy(() => import('../pages/PoliticaComentarios/PoliticaComentarios')),
  Contato: React.lazy(() => import('../pages/Contato/Contato')),
  Bebidas: React.lazy(() => import('../pages/Bebidas/Bebidas')),
  Lanches: React.lazy(() => import('../pages/Lanches/Lanches'))
} as const; 
import React from 'react';
import './LoadingSpinner.css';

interface LoadingSpinnerProps {
  overlay?: boolean;
  message?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ overlay = false, message = 'Carregando...' }) => {
  const containerClassName = overlay ? 'loading-spinner-container spinner-overlay' : 'loading-spinner-container';

  return (
    <div className={containerClassName} data-testid={overlay ? 'spinner-overlay' : 'spinner-container'}>
      <div className="loading-spinner" data-testid="loading-spinner">
        <div className="spinner" data-testid="spinner" />
        <p data-testid="spinner-message">{message}</p>
      </div>
    </div>
  );
};

export default LoadingSpinner; 
import React, { createContext, useContext, ReactNode } from 'react';

interface AdsContextType {
  adSlots: {
    home: string[];
    receitas: string[];
    detalhes: string[];
    sidebar: string[];
    receitasVeganas: string[];
    receitasVegetarianas: string[];
    bolos: string[];
    tortas: string[];
    bebidas: string[];
    lanches: string[];
    sobremesas: string[];
  };
  adsEnabled: boolean;
}

const defaultContext: AdsContextType = {
  adSlots: {
    home: ['home-top', 'home-middle', 'home-bottom'],
    receitas: ['receitas-top', 'receitas-middle', 'receitas-bottom'],
    detalhes: ['detalhes-top', 'detalhes-middle', 'detalhes-bottom'],
    sidebar: ['sidebar-top', 'sidebar-middle', 'sidebar-bottom'],
    receitasVeganas: ['veganas-top', 'veganas-middle', 'veganas-bottom'],
    receitasVegetarianas: ['vegetarianas-top', 'vegetarianas-middle', 'vegetarianas-bottom'],
    bolos: ['bolos-top', 'bolos-middle', 'bolos-bottom'],
    tortas: ['tortas-top', 'tortas-middle', 'tortas-bottom'],
    bebidas: ['bebidas-top', 'bebidas-middle', 'bebidas-bottom'],
    lanches: ['lanches-top', 'lanches-middle', 'lanches-bottom'],
    sobremesas: ['sobremesas-top', 'sobremesas-middle', 'sobremesas-bottom']
  },
  adsEnabled: true
};

const AdsContext = createContext<AdsContextType>(defaultContext);

export const useAds = () => useContext(AdsContext);

interface AdsProviderProps {
  children: ReactNode;
}

export const AdsProvider: React.FC<AdsProviderProps> = ({ children }) => {
  // Slots de anúncios para diferentes seções do site
  const adSlots = {
    home: ['home-top', 'home-middle', 'home-bottom'],
    receitas: ['receitas-top', 'receitas-middle', 'receitas-bottom'],
    detalhes: ['detalhes-top', 'detalhes-middle', 'detalhes-bottom'],
    sidebar: ['sidebar-top', 'sidebar-middle', 'sidebar-bottom'],
    receitasVeganas: ['veganas-top', 'veganas-middle', 'veganas-bottom'],
    receitasVegetarianas: ['vegetarianas-top', 'vegetarianas-middle', 'vegetarianas-bottom'],
    bolos: ['bolos-top', 'bolos-middle', 'bolos-bottom'],
    tortas: ['tortas-top', 'tortas-middle', 'tortas-bottom'],
    bebidas: ['bebidas-top', 'bebidas-middle', 'bebidas-bottom'],
    lanches: ['lanches-top', 'lanches-middle', 'lanches-bottom'],
    sobremesas: ['sobremesas-top', 'sobremesas-middle', 'sobremesas-bottom']
  };

  return (
    <AdsContext.Provider value={{ adSlots, adsEnabled: true }}>
      {children}
    </AdsContext.Provider>
  );
};

export default AdsProvider; 
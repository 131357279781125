import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface ScrollToTopOptions {
  behavior?: ScrollBehavior;
  offset?: number;
  delay?: number;
}

export const useScrollToTop = (options: ScrollToTopOptions = {}) => {
  const { behavior = 'smooth', offset = 0, delay = 0 } = options;
  const location = useLocation();

  const scrollToTop = useCallback(() => {
    const scrollOptions = {
      top: offset,
      behavior,
    };

    if (typeof window === 'undefined') {
      return;
    }

    const scrollToFn = window.scrollTo || jest.fn();

    if (delay > 0) {
      setTimeout(() => {
        if (typeof scrollOptions === 'object') {
          scrollToFn(scrollOptions);
        } else {
          scrollToFn(0, scrollOptions);
        }
      }, delay);
    } else {
      if (typeof scrollOptions === 'object') {
        scrollToFn(scrollOptions);
      } else {
        scrollToFn(0, scrollOptions);
      }
    }
  }, [behavior, offset, delay]);

  useEffect(() => {
    scrollToTop();
  }, [location, scrollToTop]);

  return scrollToTop;
};
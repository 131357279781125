import React from 'react';
import { Link } from 'react-router-dom';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import './Footer.css';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className = '' }) => {
  const scrollToTop = useScrollToTop();

  return (
    <footer className={`footer ${className}`.trim()}>
      <div className="footer-container">
        <div className="footer-section">
          <h3>Receitas Verdes</h3>
          <p>Sua fonte de inspiração para uma alimentação saudável e consciente.</p>
        </div>
        
        <div className="footer-section">
          <h3>Links Rápidos</h3>
          <ul>
            <li><Link to="/" onClick={scrollToTop}>Home</Link></li>
            <li><Link to="/sobre" onClick={scrollToTop}>Sobre</Link></li>
            <li><Link to="/contato" onClick={scrollToTop}>Contato</Link></li>
          </ul>
        </div>
        
        <div className="footer-section">
          <h3>Políticas</h3>
          <ul>
            <li><Link to="/politicas-privacidade" onClick={scrollToTop}>Política de Privacidade</Link></li>
            <li><Link to="/termos-uso" onClick={scrollToTop}>Termos de Uso</Link></li>
            <li><Link to="/politica-cookies" onClick={scrollToTop}>Política de Cookies</Link></li>
            <li><Link to="/politica-comentarios" onClick={scrollToTop}>Política de Comentários</Link></li>
          </ul>
        </div>
        
        <div className="footer-section">
          <h3>Contato</h3>
          <div className="social-media">
            <a href="#facebook">Facebook</a>
            <a href="#instagram">Instagram</a>
            <a href="#twitter">Twitter</a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; 2025 Receitas Verdes - Todos os direitos reservados</p>
      </div>
    </footer>
  );
};

export default Footer;

import { type Metric } from 'web-vitals';
import { onCLS, onFID, onFCP, onLCP, onTTFB } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: Metric) => void): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
	onCLS(onPerfEntry);
	onFID(onPerfEntry);
	onFCP(onPerfEntry);
	onLCP(onPerfEntry);
	onTTFB(onPerfEntry);
  }
};

export default reportWebVitals;

import React, { createContext, useContext } from 'react';

export interface ApiContextType {
  getImagemUrl: (foto_principal: string) => string;
}

export const ApiContext = createContext<ApiContextType>({
  getImagemUrl: (foto_principal: string) => foto_principal
});

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi deve ser usado dentro de um ApiProvider');
  }
  return context;
};

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

  const getImagemUrl = (foto_principal: string) => {
    if (!foto_principal) return '';
    if (foto_principal.startsWith('http')) return foto_principal;
    return `${baseUrl}/imagens/${foto_principal}`;
  };

  return (
    <ApiContext.Provider value={{ getImagemUrl }}>
      {children}
    </ApiContext.Provider>
  );
}; 
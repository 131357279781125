import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { ReceitasService, ReceitaSimples, CategoriaValida } from '../services/api';

// Mover refs para fora do componente
const inicializadoRef = { current: false };
const requisicaoEmAndamentoRef = { current: false };

export interface Receita extends ReceitaSimples {
  // Campos adicionais específicos para a interface Receita, se necessário
}

// Constantes
const ITEMS_POR_PAGINA = 10;

interface RequestParams {
  pagina?: number;
  categoria?: CategoriaValida;
  termoBusca?: string;
  ordenacao?: 'asc' | 'desc';
  destaque?: boolean;
  ordenar?: string;
  limit?: number;
}

export interface ReceitasContextData {
  receitas: ReceitaSimples[];
  receitasDestaque: ReceitaSimples[];
  loading: boolean;
  error: string | null;
  totalPaginas: number;
  pagina: number;
  buscarReceitas: (params?: RequestParams) => Promise<void>;
  carregarMaisReceitas: () => Promise<void>;
  getImagemUrl: (foto_principal: string) => string;
}

export const ReceitasContext = createContext<ReceitasContextData>({} as ReceitasContextData);

export const ReceitasProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [receitas, setReceitas] = useState<ReceitaSimples[]>([]);
  const [receitasDestaque, setReceitasDestaque] = useState<ReceitaSimples[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  
  // Controle de estado e cache
  const ultimaRequisicaoRef = useRef<string | null>(null);

  // Função para gerar chave única de requisição
  const gerarChaveRequisicao = useCallback((params?: RequestParams): string => {
    const normalizedParams = {
      pagina: params?.pagina || 1,
      categoria: params?.categoria || '-',
      termoBusca: params?.termoBusca || '-',
      ordenacao: params?.ordenacao || 'desc',
      destaque: params?.destaque || false,
      ordenar: params?.ordenar || '-id',
      limit: params?.limit || ITEMS_POR_PAGINA
    };
    return JSON.stringify(normalizedParams);
  }, []);

  // Função para carregar dados iniciais
  const carregarDadosIniciais = useCallback(async () => {
    if (inicializadoRef.current || requisicaoEmAndamentoRef.current) {
      console.log('[ReceitasContext] Já inicializado ou requisição em andamento, ignorando');
      return;
    }

    requisicaoEmAndamentoRef.current = true;
    console.log('[ReceitasContext] Iniciando carregamento inicial');
    
    try {
      setLoading(true);
      setError(null);

      // Carregar receitas em destaque para o carrossel
      console.log('[ReceitasContext] Carregando receitas em destaque');
      const receitasDestaqueResponse = await ReceitasService.getReceitas({
        pagina: 1,
        limit: 10,
        destaque: true,
        ordenar: '-visualizacoes'
      });
      
      // Carregar receitas normais
      console.log('[ReceitasContext] Carregando receitas normais');
      const receitasNormaisResponse = await ReceitasService.getReceitas({
        pagina: 1,
        limit: ITEMS_POR_PAGINA,
        ordenar: '-id'
      });
      
      setReceitasDestaque(receitasDestaqueResponse.data);
      setReceitas(receitasNormaisResponse.data);
      setTotalPaginas(receitasNormaisResponse.totalPages);
      inicializadoRef.current = true;
      ultimaRequisicaoRef.current = gerarChaveRequisicao();
      console.log('[ReceitasContext] Carregamento inicial concluído');

    } catch (err) {
      console.error('[ReceitasContext] Erro no carregamento inicial:', err);
      setError(err instanceof Error ? err.message : 'Erro ao carregar dados iniciais');
      inicializadoRef.current = false;
    } finally {
      setLoading(false);
      requisicaoEmAndamentoRef.current = false;
    }
  }, [gerarChaveRequisicao]);

  // Função principal de busca
  const buscarReceitas = useCallback(async (params?: RequestParams) => {
    console.log('[ReceitasContext] buscarReceitas chamado com params:', params);
    
    // Se não estiver inicializado, carrega os dados iniciais
    if (!inicializadoRef.current) {
      console.log('[ReceitasContext] Não inicializado, chamando carregarDadosIniciais');
      await carregarDadosIniciais();
      return;
    }

    if (requisicaoEmAndamentoRef.current) {
      console.log('[ReceitasContext] Requisição em andamento, ignorando');
      return;
    }

    // Gera chave única para a requisição
    const chaveRequisicao = gerarChaveRequisicao(params);
    if (chaveRequisicao === ultimaRequisicaoRef.current) {
      console.log('[ReceitasContext] Requisição duplicada detectada, ignorando');
      return;
    }

    requisicaoEmAndamentoRef.current = true;
    try {
      setLoading(true);
      setError(null);

      if (params?.pagina) {
        setPagina(params.pagina);
      }

      console.log('[ReceitasContext] Fazendo requisição com params:', params);
      const receitasResponse = await ReceitasService.getReceitas({
        ...params,
        limit: ITEMS_POR_PAGINA
      });
      
      setReceitas(receitasResponse.data);
      setTotalPaginas(receitasResponse.totalPages);
      ultimaRequisicaoRef.current = chaveRequisicao;
    } catch (err) {
      console.error('[ReceitasContext] Erro ao buscar receitas:', err);
      setError(err instanceof Error ? err.message : 'Erro ao carregar receitas');
    } finally {
      setLoading(false);
      requisicaoEmAndamentoRef.current = false;
    }
  }, [gerarChaveRequisicao, carregarDadosIniciais]);

  const carregarMaisReceitas = useCallback(async () => {
    if (pagina < totalPaginas && !loading) {
      await buscarReceitas({ pagina: pagina + 1 });
    }
  }, [pagina, totalPaginas, loading, buscarReceitas]);

  const getImagemUrl = useCallback((foto_principal: string): string => {
    return ReceitasService.getImagemUrl(foto_principal);
  }, []);

  // Efeito de inicialização única
  useEffect(() => {
    console.log('[ReceitasContext] useEffect de inicialização executado');
    carregarDadosIniciais();
    
    // Não precisamos mais do cleanup pois o inicializadoRef está fora do componente
  }, [carregarDadosIniciais]);

  return (
    <ReceitasContext.Provider
      value={{
        receitas,
        receitasDestaque,
        loading,
        error,
        pagina,
        totalPaginas,
        buscarReceitas,
        carregarMaisReceitas,
        getImagemUrl
      }}
    >
      {children}
    </ReceitasContext.Provider>
  );
};

export const useReceitas = () => {
  const context = useContext(ReceitasContext);
  if (!context) {
    throw new Error('useReceitas deve ser usado dentro de um ReceitasProvider');
  }
  return context;
};

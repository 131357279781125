import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import './Header.css';
import Logo from '../../common/Logo/Logo';

interface HeaderProps {
  title?: string;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ 
  title = 'Receitas Verdes',
  className = '' 
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const scrollToTop = useScrollToTop();
  const isDetailsPage = location.pathname.includes('/receita/');

  const menuItems = [
    { path: '/home', label: 'home' },
    { path: '/sobre', label: 'sobre' },
    { path: '/contato', label: 'Contato' },
    { path: '/politicas-privacidade', label: 'Politicas de privacidade' },
  ];

  return (
    <header className={`header ${className}`.trim()}>
      <div className="header-container">
        <div className="logo-container">
          {isDetailsPage && (
            <button 
              className="btn-voltar" 
              onClick={() => {
                navigate('/');
                scrollToTop();
              }}
              aria-label="Voltar para a página inicial"
            >
              ←
            </button>
          )}
          {!isDetailsPage ? (
            <Logo className="header-logo" />
          ) : (
            <Logo className="header-logo" linkTo="/" />
          )}
        </div>
        <nav className="main-nav">
          <ul>
            {menuItems.map((item) => (
              <li key={item.path}>
                <Link 
                  to={item.path} 
                  onClick={scrollToTop}
                  className={location.pathname === item.path ? 'active' : ''}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

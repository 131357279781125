import React from 'react';
import './Layout.css';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  theme?: 'light' | 'dark';
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl';
  padding?: 'none' | 'sm' | 'md' | 'lg';
  background?: 'white' | 'gray';
}

const Layout: React.FC<LayoutProps> = ({ 
  children, 
  className = '',
  theme = 'light',
  maxWidth = 'xl',
  padding = 'md',
  background = 'white'
}) => {
  const containerClasses = [
    'layout',
    `theme-${theme}`,
    `max-width-${maxWidth}`,
    `padding-${padding}`,
    `bg-${background}`,
    className
  ].join(' ');

  return (
    <div 
      className={containerClasses}
      data-testid="layout-container"
    >
      <header role="banner" aria-label="Cabeçalho">
        <nav role="navigation" aria-label="Navegação principal">
          {/* Conteúdo do header */}
        </nav>
      </header>

      <main 
        role="main" 
        aria-label="Conteúdo principal"
        className="layout-content"
      >
        {children}
      </main>

      <footer role="contentinfo" aria-label="Rodapé">
        {/* Conteúdo do footer */}
      </footer>
    </div>
  );
};

export default Layout; 
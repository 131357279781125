export const ROUTES = {
  HOME: '/',
  RECEITA_DETALHES: '/receita/:id',
  RECEITAS_SIMPLES: '/receitas-simples',
  RECEITAS_VEGANAS: '/receitas-veganas',
  RECEITAS_VEGETARIANAS: '/receitas-vegetarianas',
  BOLOS: '/bolos',
  TORTAS: '/tortas',
  SOBRE: '/sobre',
  POLITICAS_PRIVACIDADE: '/politicas-privacidade',
  TERMOS_USO: '/termos-uso',
  POLITICA_COOKIES: '/politica-cookies',
  POLITICA_COMENTARIOS: '/politica-comentarios',
  CONTATO: '/contato',
  BEBIDAS: '/bebidas',
  LANCHES: '/lanches'
} as const; 
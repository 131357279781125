import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import LoadingSpinner from '../components/common/LoadingSpinner/LoadingSpinner';
import { ROUTES } from './routes';
import { lazyComponents } from './lazyComponents';

const AppRoutes: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route 
          path={ROUTES.HOME} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.Home />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.RECEITA_DETALHES} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.DetalhesReceita />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.RECEITAS_VEGANAS} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.ReceitasVeganas />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.RECEITAS_VEGETARIANAS} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.ReceitasVegetarianas />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.BOLOS} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.Bolos />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.TORTAS} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.Tortas />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.BEBIDAS} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.Bebidas />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.LANCHES} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.Lanches />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.SOBRE} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.Sobre />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.CONTATO} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.Contato />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.POLITICAS_PRIVACIDADE} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.PoliticasPrivacidade />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.TERMOS_USO} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.TermosUso />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.POLITICA_COOKIES} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.PoliticaCookies />
            </Suspense>
          } 
        />
        <Route 
          path={ROUTES.POLITICA_COMENTARIOS} 
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <lazyComponents.PoliticaComentarios />
            </Suspense>
          } 
        />
        <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
      </Routes>
    </Layout>
  );
};

export default AppRoutes; 
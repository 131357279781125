import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ReceitasProvider } from './context/ReceitasContext';
import { AdsProvider } from './context/AdsContext';
import { AmazonProvider } from './context/AmazonContext';
import { ApiProvider } from './context/ApiContext';
import AppRoutes from './routes';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import './App.css';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ReceitasProvider>
          <ApiProvider>
            <AdsProvider>
              <AmazonProvider>
                <Header />
                <main className="main-content">
                  <AppRoutes />
                </main>
                <Footer />
              </AmazonProvider>
            </AdsProvider>
          </ApiProvider>
        </ReceitasProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
